import { API_URL } from "../util/api";
import { Link } from "react-router-dom";
import React from "react";
import axios from "axios";

export class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      mereks: [],
    };
  }

  componentDidMount() {
    axios
      .get(API_URL + "getBanner.php")
      .then((response) => {
        this.setState({
          banners: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(API_URL + "getMerek.php")
      .then((response) => {
        this.setState({
          mereks: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div className="container-fluid px-0">
          <div
            id="carouselId"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div
              className="carousel-inner"
              role="listbox"
              aria-label="Green,Tagawa,Hayashi,Karcher dan Chemical"
            >
              {this.state.banners.map((banner, idx) =>
                idx === 1 ? (
                  <div className="carousel-item active">
           
                    <img
                      fetchpriority="high"
                      rel="preload"
                      src={API_URL + "/imgBanner/" + banner.imgBanner}
                      className="img-fluid"
                      alt={banner.imgBanner}
                      height={"100%"}
                      width={"100%"}
                    />
                    <div className="carousel-caption">
                      <div className="container carousel-content"></div>
                    </div>
                  </div>
                ) : (
                  <div className="carousel-item">
                    <img
                      fetchpriority="high"
                      rel="preload"
                      src={API_URL + "/imgBanner/" + banner.imgBanner}
                      className="img-fluid"
                      alt={banner.imgBanner}
                      height={"100%"}
                      width={"100%"}
                    />
                    <div className="carousel-caption">
                      <div className="container carousel-content"></div>
                    </div>
                  </div>
                )
              )}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselId"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselId"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="container-fluid bg-secondary py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 wow fadeIn" data-wow-delay=".1s">
                <div className="d-flex counter">
                  <h1
                    className="me-3 counter-value"
                    style={{ color: "#0A1D57" }}
                  >
                    20
                  </h1>
                  <h2 className="mt-1" style={{ color: "#5C002E" }}>
                    Tahun Kita sudah berdiri
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 wow fadeIn" data-wow-delay=".3s">
                <div className="d-flex counter">
                  <h1
                    className="me-3 counter-value"
                    style={{ color: "#0A1D57" }}
                  >
                    1000
                  </h1>
                  <h2 className="mt-1" style={{ color: "#5C002E" }}>
                    Lebih produk kita miliki
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 wow fadeIn" data-wow-delay=".5s">
                <div className="d-flex counter">
                  <h1
                    className="me-3 counter-value"
                    style={{ color: "#0A1D57" }}
                  >
                    500
                  </h1>
                  <h2 className="mt-1" style={{ color: "#5C002E" }}>
                    Lebih customer sudah mempercayai kita
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid services py-5 mb-5">
          <div className="container">
            <div
              className="text-center mx-auto pb-5 wow fadeIn"
              data-wow-delay=".3s"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="text-primary">Service Center Kami</h5>
              <h1>Kita memiliki 3 area service center</h1>
            </div>
            <div className="row g-5 services-inner">
              <div
                className="col-md-6 col-lg-4 wow fadeIn"
                data-wow-delay=".3s"
              >
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <img
                      src="assets/img/bwkpusat.webp"
                      width="350px"
                      height="auto"
                      alt="Foto Bintang Wistar Kencana Jakarta"
                    />
                    <div className="services-content-icon">
                      <h2 className="mb-3">Jakarta Area</h2>
                      <p className="mb-4 text-dark">
                        Berlokasi pada utara kota Jakarta , Kami melayani
                        service pada setiap pembelian produk kami
                      </p>
                      <Link
                        to={"/serviceArea/1"}
                        className="btn btn-secondary px-5 py-3 rounded-pill"
                        style={{ color: "#5C002E" }}
                      >
                        Lihat Lokasi Di Jakarta
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 wow fadeIn"
                data-wow-delay=".5s"
              >
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <img
                      src="assets/img/bwksby.webp"
                      width="350px"
                      height="auto"
                      alt="Foto Bintang Wistar Kencana Surabaya"
                    />
                    <div className="services-content-icon">
                      <h2 className="mb-3">Surabaya Area</h2>
                      <p className="mb-4 text-dark">
                        Kami melayani service pada setiap pembelian produk kami
                        pada area Surabaya dan sekitarnya
                      </p>
                      <Link
                        to={"/serviceArea/2"}
                        className="btn btn-secondary px-5 py-3 rounded-pill"
                        style={{ color: "#5C002E" }}
                      >
                        Lihat Lokasi Di Surabaya
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 wow fadeIn"
                data-wow-delay=".7s"
              >
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <img
                      src="assets/img/bwkbali.webp"
                      width="350px"
                      height="auto"
                      alt="Foto Bintang Wistar Kencana Bali"
                    />
                    <div className="services-content-icon">
                      <h2 className="mb-3">Bali Area</h2>
                      <p className="mb-4 text-dark">
                        Kami melayani service pada setiap pembelian produk kami
                        pada area bali dan sekitarnya
                      </p>
                      <Link
                        to={"/serviceArea/3"}
                        className="btn btn-secondary px-5 py-3 rounded-pill"
                        style={{ color: "#5C002E" }}
                      >
                        Lihat Lokasi Di Bali
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid project py-5 mb-5">
          <div className="container">
            <div
              className="text-center mx-auto pb-5 wow fadeIn"
              data-wow-delay=".3s"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="text-primary">Produk Kami</h5>
              <h1>Berbagai macam merek yang kami miliki</h1>
            </div>
            <div className="row g-5">
              {this.state.mereks.map((merek, idx) => (
                <div
                  className="col-md-6 col-lg-4 wow fadeIn"
                  data-wow-delay=".3s"
                >
                  <div className="project-item">
                    <div className="project-img">
                      <img
                        src={API_URL + "/imgMerek/" + merek.imgMerek}
                        className="img-fluid w-100 h-100 rounded"
                        alt={merek.namaMerek}
                      />
                      <div className="project-content">
                        <nav>
                          <Link to={"/product/" + merek.idMerek}>
                            {" "}
                            <h4 className="text-secondary">
                              {merek.namaMerek}
                            </h4>{" "}
                            <p className="m-0 text-white">
                              Terdapat Berbagai macam Produk dari{" "}
                              {merek.namaMerek}
                            </p>
                          </Link>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
