import "./App.css";
import { Home } from "./components/Home";
import { Notfound } from "./components/Notfound";
import { Routes, Route } from "react-router-dom";
import Product from "./components/Product";
// import ProductDetail from "./components/ProductDetail";
import { Link } from "react-router-dom";
import ServiceArea from "./components/ServiceArea";
//import Tes from "./components/Tes";
import ProductDetail from "./components/Tes";
import { About } from "./components/About";
import { ServiceAreaPage } from "./components/ServiceAreaPage";

function App() {
  return (
    <>
      <div className="container-fluid bg-dark py-2 d-none d-md-flex">
        <div className="container">
          <div className="d-flex justify-content-between topbar">
            <div className="top-info">
              <small className="me-3 text-white-50">
                <Link to={"/serviceArea/1"}  aria-label="Alamat Pusat PT Bintang Wistar Kencana">
                  <i className="fas fa-map-marker-alt me-2 text-secondary"></i>
                </Link>
                JL. Agung Timur X, Blok N-2/19, Jakarta
              </small>
              <small className="me-3 text-white-50">
                  <i className="fas fa-envelope me-2 text-secondary"></i>
                  admin@bintangwistar.com
              </small>
            </div>
            <div id="note" className="text-secondary d-none d-xl-flex">
              <small>
                Note : Hubungi Sales Kami untuk mendapatkan penawaran terbaik
              </small>
            </div>
            <div className="top-link">
                <Link
                to={"https://www.instagram.com/bintangwistarkencana/"}
                target="_blank"
                className="bg-light nav-fill btn btn-sm-square rounded-circle"
                aria-label="Instagram PT Bintang Wistar Kencana"
              >
                <i className="fab fa-instagram text-primary"></i>
              </Link>
              <Link
                to={"https://www.linkedin.com/company/pt-bintang-wistar-kencana/about/"}
                target="_blank"
                className="bg-light nav-fill btn btn-sm-square rounded-circle me-0"
                aria-label="LinkedIn PT Bintang Wistar Kencana"
              >
                <i className="fab fa-linkedin-in text-primary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-primary">
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-0">
            <Link to={"/"} className="navbar-brand">
              <h1 className="text-white fw-bold d-block">
                {" "}
                <img src={"/assets/img/logobwkputih.webp"} width="59px" height="auto" alt="Logo Bintang Wistar Kencana"/>{" "}
                Bintang Wistar Kencana {" "}
              </h1>
            </Link>
            <button
              type="button"
              className="navbar-toggler me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse bg-transparent"
              id="navbarCollapse"
            >
              <div className="navbar-nav ms-auto mx-xl-auto p-0">
                <Link to="/" className="nav-item nav-link">
                  Beranda
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  Tentang{" "}
                </Link>
                <Link to="/serviceAreaPage" className="nav-item nav-link">
                  Service Center
                </Link>
                <Link to="https://e-katalog.bintangwistar.com/" target="_blank" className="nav-item nav-link">
                  Katalog
                </Link>
              </div>
            </div>
            <div className="d-none d-xl-flex flex-shirink-0">
              <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                <Link to={"/"} aria-label="Telepon PT Bintang Wistar Kencana" className="position-relative animated tada infinite">
                  <i className="fa fa-phone-alt text-white fa-2x"></i>
                  <div
                    className="position-absolute"
                    style={{ top: "-7px", left: "20px" }}
                  >
                    <span>
                      <i className="fa fa-comment-dots text-secondary"></i>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="d-flex flex-column pe-4 border-end">
                <span className="text-white">Telepon : (021) 6506589</span>
              </div>
              {/* <div className="d-flex align-items-center justify-content-center ms-4 ">
                <Link to={"#"}>
                  <i className="bi bi-search text-white fa-2x"></i>{" "}
                </Link>
              </div> */}
            </div>
          </nav>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:userid" element={<Product />} />
        <Route path="/serviceArea/:id" element={<ServiceArea />} />
        <Route path="/about" element={<About />} />
        <Route path="/serviceAreaPage" element={<ServiceAreaPage />} />
        <Route path="/productDetail/:id" element={<ProductDetail />} />

        <Route path="*" element={<Notfound />} />
      </Routes>

      <div
        className="container-fluid footer bg-dark wow fadeIn"
        data-wow-delay=".3s"
      >
        <div className="container pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <Link to={"/"}>
                <h1 className="text-white fw-bold d-block">
                  Bintang Wistar Kencana
                </h1>
              </Link>
              <p className="mt-4 text-light" >
                Our Commitment : <br></br>
                - Trading Services <br></br>
                - Warehousing <br></br>
                - Export & Import <br></br>
                - Repacking & Daily Delivery <br></br>
                - Service Center
              </p>
              <div className="d-flex hightech-link">
                {/* <Link
                  to={"#"}
                  className="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <i className="fab fa-facebook-f text-primary"></i>
                </Link> */}
              <Link
                to={"https://www.instagram.com/bintangwistarkencana/"}
                target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-2"
                aria-label="Instagram PT Bintang Wistar Kencana"
              >
                <i className="fab fa-instagram text-primary"></i>
              </Link>
              <Link
                to={"https://www.linkedin.com/company/pt-bintang-wistar-kencana/about/"}
                className="btn-light nav-fill btn btn-square rounded-circle me-0"
                target="_blank"
                aria-label="LinkedIn PT Bintang Wistar Kencana"
              >
                <i className="fab fa-linkedin-in text-primary"></i>
              </Link>
             
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
               <p className="h3 text-secondary"> Tautan Cepat
               </p>
              <div className="mt-4 d-flex flex-column short-link">
                <Link to="/about" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Tentang Kami
                </Link>

                <Link to="/serviceAreaPage" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Service Center
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to={""} className="h3 text-secondary">
                Bantuan Tautan
              </Link>
              <div className="mt-4 d-flex flex-column help-link">
                <Link to={"/product/11"} className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Karcher
                </Link>
                <Link to={"/product/10"} className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Green
                </Link>
                <Link to={"/product/7"} className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Tagawa
                </Link>
                <Link to={"/product/5"} className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Hayashi
                </Link>
                <Link to={"/product/6"} className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Emic
                </Link>
                <Link to={"/product/53"} className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>
                  Ichinen
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <p className="h3 text-secondary">
                Hubungi Kami
              </p>
              <div className="text-white mt-4 d-flex flex-column contact-link">
                <Link to={""}
                  className="pb-3 text-light border-bottom border-primary"
                >
                  <i className="fas fa-map-marker-alt text-secondary me-2"></i>{" "}
                  JL. Agung Timur X, Blok N-2/19, Jakarta, 14350, RT.10/RW.11,
                  Sunter Jaya, Tanjung Priok, North Jakarta City, Jakarta 14350
                </Link>
                <Link to={""}
                  className="py-3 text-light border-bottom border-primary"
                >
                  <i className="fas fa-phone-alt text-secondary me-2"></i> (021)
                  6506589
                </Link>
                <Link to={""}
                  className="py-3 text-light border-bottom border-primary"
                >
                  <i className="fas fa-envelope text-secondary me-2"></i>{" "}
                  admin@bintangwistar.com
                </Link>
              </div>
            </div>
          </div>
          <hr className="text-light mt-5 mb-4" />
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <span className="text-light">
                <Link to={""} className="text-secondary">
                  <i className="fas fa-copyright text-secondary me-2"></i>2024
                  Bintang Wistar Kencana
                </Link>
                , All right reserved.
              </span>
            </div>
            <div className="col-md-6 text-center text-md-end"></div>
          </div>
        </div>
      </div>
      <a
          href="https://api.whatsapp.com/send?phone=6285718788905&text=Hallo%21%20Saya%20Tertarik%20Dengan%20Informasi%20diWebsite%20"
          class="float"
          target="_blank"
        >
          <i class="fab fa-whatsapp my-float"></i>
        </a>
    </>
  );
}

export default App;
