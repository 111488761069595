import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import style from "../css/map.css";
import { useParams } from "react-router-dom";
import { Component } from "react";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class ServiceArea extends Component {
  render() {
    let position = "";
    let street = "";
    let name = "";
    const id = this.props.params.id;

    if (id === "1") {
      position = [-6.1403114154852805, 106.88026441168148];
      name = "PT Bintang Wistar Kencana Jakarta";
      street = "JL. Agung Timur X Blok N-2/19, Jakarta, 14350, RT.10/RW.11,Sunter Jaya, Tanjung Priok, North Jakarta City, Jakarta 14350.";
    } else if (id === "2") {
      position = [-7.364285044795565, 112.68403171986309];
      name = "PT Bintang Wistar Kencana Surabaya";
      street = "Kompleks Pergudangan Tritan Blok C 36-37, Jemundo, Taman, Jemundo, Sidoarjo, Kabupaten Sidoarjo, Jawa Timur 61257";
    } else if (id === "3") {
      position = [-8.638682215988418, 115.20382377555829];
      name = "PT Bintang Wistar Kencana Bali";
      street = "Jl. Bung Tomo No.311A, Pemecutan Kaja, Kec. Denpasar Utara, Kota Denpasar, Bali 80111";
    }
    return (
      <div>
        <MapContainer
          center={position}
          zoom={13}
          scrollWheelZoom={true}
          id="mapid"
        >
          <TileLayer
            attribution='&copy; <a href="#">Google Maps</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
                <b>{name}</b> <br/>
                {street}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    );
  }
}

export default withParams(ServiceArea);
