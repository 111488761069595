import axios from "axios";
import { Component } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../util/api";
import { Link } from "react-router-dom";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [],
      produks: [],
    };
  }

  componentDidMount() {
    const id = this.props.params.userid;
    axios
      .get(API_URL + "getHeader.php?idMerek=" + id)
      .then((response) => {
        this.setState({
          headers: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(API_URL + "getProduk.php?idMerek=" + id)
      .then((response) => {
        this.setState({
          produks: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div className="container-fluid px-0">
          <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                {this.state.headers.map((header, id) => (
                  <img fetchpriority="high"
                    src={API_URL + "imgHeader/" + header.img}
                    className="img-fluid"
                    alt={header.img}
                    width={"100%"}
                    height={"100%"}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid blog py-5 mb-5">
          <div className="container">
            <div className="row g-5 justify-content-center">
              {this.state.produks.map((produk, id) => (
                <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
                  <div className="blog-item position-relative bg-light rounded">
                    <img fetchpriority="high"
                      src={API_URL+"imgProduk/"+produk.imgProduk}
                      className="img-fluid rounded-top"
                      alt={produk.namaProduk}
                      style={{width:"438px",height:"310px"}}
                    />   
                    <span
                      className="position-absolute px-4 py-3 bg-primary text-white rounded"
                      style={{top: "-28px", right: "20px"}}
                    >
                      {produk.namaProduk}
                    </span>
                    <div className="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                      <Link to={`/productDetail/${produk.idProduk}`} className="text-white">
                        <small>
                          <i className="fas fa-share me-2 text-secondary"></i>Lihat
                          Detail Produk {produk.namaProduk}
                        </small>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(Product);
