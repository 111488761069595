import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { API_URL } from "../util/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import style from "../css/thumbnailProduk.css";
import Zoom from "react-img-zoom-gdn";

const ProductDetail = () => {
  const [image, setImage] = useState([]);
  const [product, setProduct] = useState([]);
  const [similarproduct, setSimilarProduct] = useState([]);
  const id = useParams();
  const idParam = id.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          (await API_URL) + "getDetailImageProduk.php?idProduk=" + idParam
        );
        setImage(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    axios
    .get(API_URL + "getDetailProduk.php?idProduk=" + idParam)
    .then((response) => {
      setProduct(response.data);
    });
  }, []);

  useEffect(() => {
    axios
    .get(API_URL + "getBarangSerupa.php?idProduk=" + idParam)
    .then((response) => {
      setSimilarProduct(response.data);
    });
  }, []);
  let message = "";
  let show = "";

  if (similarproduct.length === 0) {
    message = "Tidak ada barang dengan grup yang sama dengan barang ini";
  }

  if (similarproduct.length < 4) {
    if (similarproduct.length === 1) {
      show = 1;
    } else if (similarproduct.length === 2) {
      show = 2;
    }else{
      show = 3;
    }
  } else {
    show = 4;
  }

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    slidesToShow: show,
    slidesToScroll: 1,
    speed: 500,
    centerMode : true
  };


  // console.log(image)
  let status = "";

  if(image.length <= 5){
    status = true;
  }else{
    status = false;
  }
  return (
    <div>
    
      <div className="container-fluid blog">
        <div className="container">
          <div className="container-fluid team ">
            <div className="container py-5">
              <div
                className="text-center mx-auto mb-5 wow fadeIn"
                data-wow-delay="0.1s"
                style={{
                  maxWidth: "700px",
                  visibility: "visible",
                  animatioDelay: "0.1s",
                  animationName: "fadeIn",
                }}
              >
                <p className="fs-5 text-uppercase text-primary">
                  Detail Produk
                </p>
              </div>
              <div className="row g-5">
                <div className="col-lg-8 col-xl-7">
                  <div className="p-4">
                    <ImageGallery
                      items={image}
                      thumbnailPosition="left"
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showNav={false}
                      thumbnailAlt={image}
                      disableThumbnailScroll={status}
                      renderItem={(images) => {
                        return (
                          <div
                            className="w-full"
                            data-original={images.original}
                            onClick={(event) => {
                              const itemIndex = image.findIndex(
                                (images) =>
                                  images.original ===
                                  event.currentTarget.dataset.original
                              );
                              console.log("itemIndex", itemIndex);
                            }}
                          >
                            <div
                              className="team-img wow zoomIn"
                              data-wow-delay="0.1s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.1s",
                                animationName: "zoomIn",
                                border: "5px solid #1ec881",
                                marginLeft: "auto",
                                marginRight: "auto",
                                paddingTop:"30px",
                                width: "421px",
                                height:"380px"
                              }}
                            >
                              <Zoom
                                img={images.original}
                                zoomScale={3}
                                height={318}
                                width={412}
                                transitionTime={0.7}
                              />
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                {product.map((products) => 
                  <div className="col-lg-4 col-xl-5 text-dark">
                    <div
                      className="team-item wow fadeIn"
                      data-wow-delay="0.1s"
                      style={{
                        visibility: "visible",
                        paddingLeft: "13px",
                        animationDelay: "0.1s",
                        animationName: "fadeIn",
                      }}
                    >
                      <h1>{products.namaProduk}</h1>
                      <h2 className="fw-normal fst-italic text-primary mb-4">
                        Group : {products.namaGrup}
                      </h2>
                      <p className="mb-4" style={{ whiteSpace: "pre-wrap" }}>
                        {products.deskripsi}
                      </p>
                      <div className="team-icon d-flex pb-4 mb-2 border-bottom border-primary"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="text-center mx-auto pb-5 wow fadeIn"
        data-wow-delay=".3s"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="text-primary">Barang Serupa</h5>
      </div>
      
      <div className="container">
        <h3 className="text-center">{message}</h3>
        <Slider {...settings}>
          {similarproduct.map((similarproducts) => 
            <div>
              <Link
              aria-label="Lihat Detail Produk Lainnya"
                to={"/productDetail/" + similarproducts.idProduk}
                onClick={() =>
                  (window.location.href = "/productDetail/" + similarproducts.idProduk)
                }
              >
                <img
                  src={API_URL + "/imgProduk/" + similarproducts.imgProduk}
                  alt={similarproducts.imgProduk}
                  style={{
                    width: "202px",
                    height: "155px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Link>
            </div>
          )}
        </Slider>
      </div>

      <div style={{ marginBottom: "50px" }}></div>
    </div>
  );
};

export default ProductDetail;
