import { Link } from "react-router-dom";
export const ServiceAreaPage = () => {
    return <div>
        <div className="container-fluid services py-5 mb-5">
          <div className="container">
            <div
              className="text-center mx-auto pb-5 wow fadeIn"
              data-wow-delay=".3s"
              style={{ maxWidth: "600px" }}
            >
              <h5 className="text-primary">Service Center Kami</h5>
              <h1>Kita memiliki 3 area service center</h1>
            </div>
            <div className="row g-5 services-inner">
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <img src="assets/img/bwkpusat.webp" width="350px" height="auto" alt="Foto Bintang Wistar Kencana Jakarta"/>
                    <div className="services-content-icon">
                      <h2 className="mb-3">Jakarta Area</h2>
                      <p className="mb-4 text-dark">
                        Berlokasi pada utara kota Jakarta , Kami melayani service pada setiap pembelian produk kami 
                      </p>
                      <Link to={"/serviceArea/1"}
                        className="btn btn-secondary px-5 py-3 rounded-pill"
                        style={{color:"#5C002E"}}
                      >
                        Lihat Lokasi Di Jakarta
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <img src="assets/img/bwksby.webp" width="350px" height="auto" alt="Foto Bintang Wistar Kencana Surabaya"/>
                    <div className="services-content-icon">
                      <h2 className="mb-3">Surabaya Area</h2>
                      <p className="mb-4 text-dark">
                      Kami melayani service pada setiap pembelian produk kami pada area Surabaya dan sekitarnya
                      </p>
                      <Link to={"/serviceArea/2"}
                        className="btn btn-secondary px-5 py-3 rounded-pill"
                        style={{color:"#5C002E"}}
                      >
                        Lihat Lokasi Di Surabaya
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <img src="assets/img/bwkbali.webp" width="350px" height="auto" alt="Foto Bintang Wistar Kencana Bali"/>
                    <div className="services-content-icon">
                      <h2 className="mb-3">Bali Area</h2>
                      <p className="mb-4 text-dark">
                       Kami melayani service pada setiap pembelian produk kami pada area bali dan sekitarnya
                      </p>
                      <Link to={"/serviceArea/3"}
                        className="btn btn-secondary px-5 py-3 rounded-pill"
                        style={{color:"#5C002E"}}
                      >
                        Lihat Lokasi Di Bali
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
}
