export const About = () => {
  return (
    <div>
      <div className="container-fluid blog py-5 mb-5">
        <div className="container text-dark">
          <h3 className="mb-3 text-center">Company Profile</h3>

          <h4>DIRECTOR MESSAGE </h4>
          
          PT. Bintang Wistar Kencana was established on 03rd of
          April 2003 and started the business as an OEM (Original Equipment
          Manufacturing) Stock Point. On 23rd of December 2005, we received the
          ISO 9001:2000 ISO Certificate for Trading Service, Export & Import,
          Repacking & Distribution Service, Stock Point Service, and Assembly
          Line for Motorcycle Part & Electric Part of Home Appliances. This
          shows the spirit and commitment to achieve our goal as an
          Internationally Recognised Trading Company. Our Pride grew higher as
          PT. Bintang Wistar Kencana is not only capable of being a Stock Point
          Service company, but also increased in its growth by becoming a Power
          Product Trading Company. 
          <div className="mb-3"></div>
          <h4>HISTORY </h4>
          PT. Bintang Wistar Kencana was
          established on 03rd April 2003 by Mr. Sentoso Hermawan and Mr. Fujita
          (Wistar Corp. Japan) with a capital of US$300,000,- and 10 manpower.
          On a land of 250m² (Office = 130m², Warehouse = 120m²), PT. Bintang
          Wistar Kencana started its business as an OEM Stock Point. Betweem the
          year of 2005 – 2007, PT. Bintang Wistar Kencana handled the assembling
          of OEM parts for 2 wheeled vehicles such as Plate Comp Stopper and
          Gearbox Assy Speedometer. Starting of August 2006 until current time,
          PT. Bintang Wistar Kencana formed a department of Power Product, which
          its goal was to trade many kinds of machines such as Multi Purpose
          Gasoline Engine, Water Pump, Generator, Welder, and supporting Spare
          Part to all regions of Indonesia. In December 2007, PT. Bintang Wistar
          Kencana formed a new department of Kanagata Sales which trade Mold and
          Dies to the factoried in Jabotabek regions. By end of July 2009, Mr.
          Sentoso Hermawan and Mr. Fujita (Wistar Corp. Japan) ended the
          co-operation, and agreed by both party, Kanagata Sales Department will
          be taken over by Mr. Fujita (Wistar Corp. Japan). PT. Bintang Wistar
          Kencana then runned its business side by side with a few partners and
          a foreign company from Singapore which is Federal Multi Supply PTE.,
          LTD (FMS). By the month of September 2009, PT. Bintang Wistar Kencana
          started to assemble many kinds of machines such as Multi Purpose
          Gasoline Engine, Water Pump, etc. With a good management system, all
          products are well stored and the quality of the products can be
          maintained. 
          
          <div className="mb-3"></div>
          <h4>COMPANY OVERVIEW</h4> 
          The area width of PT. Bintang Wistar Kencana is 3,055m², and the building width is as follows: 
          Office = 1,200 m2 
          Warehouse = 1,100 m2 
          By the end of 2008, PT. Bintang Wistar Kencana’s capital was increased to US$ 3,600,000,- and had more than
          80 manpower. 
          <div className="mb-3"></div>
          <h4>OUR VISION </h4>
          To be a Trading Company with an International Standard OUR MISSION To improve our
          company’s reputation by giving quality services and products To
          improve our stakeholder’s value and quality 
          <div className="mb-3"></div>
          <h4>COMMITMENT</h4> 
          Our company
          commit in maintaining the quality of our product, so in return we can
          provide the products of our standards to our customers. Our company
          commit to continually improve our performance and quality effectively
          and efficiently to satisfy our customers. 
          <div className="mb-3"></div>
          <h4>OUR BUSINESS </h4>
          Trading Services Warehousing Export & Import Repacking & Daily Delivery
          Service Center 
          <div className="mb-3"></div>
          <h4>AFTER SALES SERVICE </h4>
          PT. Bintang Wistar Kencana also has
          a Service Center department which handles technical problems or repair
          the damages of all Power Products.
          <div className="mb-3"></div>
          <h4> CERTIFICATE</h4> 
          In the year of 2005,
          PT. Bintang Wistar Kencana succeeded to accept the ISO 9001:2000
          Certificate. Then, by the year of 2009, PT. Bintang Wistar Kencana
          succeeded to upgrade to ISO 9001:2008.
        </div>
      </div>
    </div>
  );
};
